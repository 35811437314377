<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <ProgressBar
                    v-if="getProfileLoading"
                    mode="indeterminate"
                    class="custom-progress-bar"/>
                <TabView v-model:activeIndex="activeIndex">
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-user-edit mr-2"></i>
                            <span>Общая информация</span>
                        </template>
                        <div class="py-4">
                            <form @submit.prevent="handleProfileSubmit">
                                <div class="p-fluid">
                                    <div class="field grid">
                                        <label for="name" class="col-12 mb-2 md:col-2 mb-0">
                                            <i class="pi pi-user mr-2"/>
                                            Имя пользователя
                                        </label>
                                        <div class="col-12 md:col-3">
                                            <InputText id="name" v-model="profileForm.fields.name"
                                                       placeholder="Имя пользователя"
                                                       :area-invalid="profileForm.errors.name ? true : null"
                                                       :aria-describedby="profileForm.errors.name ? profileForm.errors.name : null"
                                                       :class="{ 'p-invalid' : profileForm.errors.name }"/>
                                            <div v-if="profileForm.errors.name" class="p-error text-left">
                                                <small>{{ profileForm.errors.name }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field grid">
                                        <label for="name" class="col-12 mb-2 md:col-2 mb-0">
                                            <i class="pi pi-envelope mr-2"/>
                                            Е-майл
                                        </label>
                                        <div class="col-12 md:col-3">
                                            <InputText id="email" v-model="profileForm.fields.email"
                                                       type="email"
                                                       placeholder="Е-майл"
                                                       :area-invalid="profileForm.errors.email ? true : null"
                                                       :aria-describedby="profileForm.errors.email ? profileForm.errors.email : null"
                                                       :class="{ 'p-invalid' : profileForm.errors.email }"/>
                                            <div v-if="profileForm.errors.email" class="p-error text-left">
                                                <small>{{ profileForm.errors.email }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="col-12 md:col-3 md:col-offset-2 text-right">
                                        <Button
                                            label="Обновить профиль"
                                            type="submit"
                                            :loading="updateProfileLoading || getProfileLoading"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-shield mr-2"></i>
                            <span>Безопасность</span>
                        </template>
                        <Message severity="info" :closable="false">Внимание! Сотрудники проекта никогда не спрашивают
                            Ваш
                            пароль!
                        </Message>
                        <Divider align="center">
                            <div class="inline-flex align-items-center">
                                <i class="pi pi-lock mr-2"></i>
                                <b>Смена пароля</b>
                            </div>
                        </Divider>
                        <div class="py-4">
                            <form @submit.prevent="handlePasswordSubmit">
                                <div class="p-fluid">
                                    <div class="field grid">
                                        <label for="password" class="col-12 mb-2 md:col-2 mb-0">
                                            <i class="pi pi-lock mr-2"/>
                                            Пароль
                                        </label>
                                        <div class="col-12 md:col-3">
                                            <Password id="password" v-model="passwordForm.fields.password"
                                                      placeholder="Пароль"
                                                      toggleMask
                                                      :area-invalid="passwordForm.errors.password ? true : null"
                                                      :aria-describedby="passwordForm.errors.password ? passwordForm.errors.password : null"
                                                      :class="{ 'p-invalid' : passwordForm.errors.password }"/>
                                            <div v-if="passwordForm.errors.password" class="p-error text-left">
                                                <small>{{ passwordForm.errors.password }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field grid">
                                        <label for="password_confirmation" class="col-12 mb-2 md:col-2 mb-0">
                                            <i class="pi pi-lock mr-2"/>
                                            Пароль повторно
                                        </label>
                                        <div class="col-12 md:col-3">
                                            <Password id="password_confirmation"
                                                      v-model="passwordForm.fields.password_confirmation"
                                                      placeholder="Пароль повторно"
                                                      toggleMask
                                                      :area-invalid="passwordForm.errors.password ? true : null"
                                                      :aria-describedby="passwordForm.errors.password ? passwordForm.errors.password: null"
                                                      :class="{ 'p-invalid' : passwordForm.errors.password }"/>
                                            <div v-if="passwordForm.errors.password" class="p-error text-left">
                                                <small>{{ passwordForm.errors.password }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="col-12 md:col-3 md:col-offset-2 text-right">
                                        <Button
                                            label="Обновить пароль"
                                            type="submit"
                                            :loading="changePasswordLoading"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Divider align="center">
                            <div class="inline-flex align-items-center">
                                <i class="pi pi-mobile mr-2"></i>
                                <b>Двухфакторная аутентификация</b>
                            </div>
                        </Divider>
                        <div class="grid py-4" v-if="getProfile">
                            <Button
                                v-if="getProfile.two_factor_confirmed"
                                label="Отлючить двухфакторную аутентификацию"
                                :loading="disableTwoFactorAuthenticationLoading"
                                @click="disableTwoFactorAuthentication"
                            />
                            <template v-else-if="getProfile.two_factor_secret">
                                <div class="col-12 md:col-2 justify-content-center align-items-center flex flex-column">
                                    <ProgressBar
                                        v-if="qrCodeTwoFactorAuthenticationLoading"
                                        mode="indeterminate"
                                        class="custom-progress-bar"/>
                                    <template v-else-if="qrCode">
                                        <div>{{ qrCode.code}}</div>
                                        <div
                                            class="my-2"
                                            v-html="qrCode.svg">
                                        </div>
                                    </template>
                                </div>
                                <div class="col-12 md:col-3">
                                    <div class="p-fluid">
                                        <div class="field grid">
                                            <label for="code" class="col-12 mb-2">
                                                <i class="pi pi-mobile mr-2"/>
                                                Введите код с устройства
                                            </label>
                                            <div class="col-12 mb-3">
                                                <InputText id="code"
                                                           v-model="twoFactorAuthenticationForm.fields.two_factor_code"
                                                           placeholder="XXXXXXXX"
                                                           :area-invalid="twoFactorAuthenticationForm.errors.two_factor_code ? true : null"
                                                           :aria-describedby="twoFactorAuthenticationForm.errors.two_factor_code ? profileForm.errors.two_factor_code : null"
                                                           :class="{ 'p-invalid' : twoFactorAuthenticationForm.errors.two_factor_code }"/>
                                                <div v-if="twoFactorAuthenticationForm.errors.two_factor_code"
                                                     class="p-error text-left">
                                                    <small>
                                                        {{ twoFactorAuthenticationForm.errors.two_factor_code }}
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <Button
                                                    label="Подтвердить"
                                                    :loading="validateTwoFactorAuthenticationLoading"
                                                    @click="validateTwoFactorAuthentication"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <Button
                                v-else
                                label="Включить двухфакторную аутентификацию"
                                :loading="enableTwoFactorAuthenticationLoading || qrCodeTwoFactorAuthenticationLoading"
                                @click="enableTwoFactorAuthentication"
                            />
                        </div>
                        <div v-else class="custom-skeleton py-4">
                            <Skeleton width="100%" height="125px"></Skeleton>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-cog mr-2"></i>
                            <span>Настройки</span>
                        </template>
                        <div class="py-4">
                            <InlineMessage severity="info">В ближайшее время будет доступен новый функционал
                            </InlineMessage>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed, watch } from 'vue'
import ToastMessageService from '../services/ToastMessageService'
import UserService from '../services/UserService'
import FormService from '../services/FormService'
import AuthService from '../services/AuthService'
import { useStore } from 'vuex'

export default {
    setup () {
        const store = useStore()
        const userService = new UserService()
        const formService = new FormService()
        const authService = new AuthService()
        const toastMessageService = new ToastMessageService()

        userService.getProfile()

        const activeIndex = ref(0)
        const getProfileLoading = computed(() => store.state.user.getProfileLoading)
        const getProfile = computed(() => store.state.user.getProfile)
        const updateProfileLoading = computed(() => store.state.user.updateProfileLoading)
        const changePasswordLoading = computed(() => store.state.user.changePasswordLoading)
        const enableTwoFactorAuthenticationLoading = computed(() => store.state.auth.enableTwoFactorAuthenticationLoading)
        const disableTwoFactorAuthenticationLoading = computed(() => store.state.auth.disableTwoFactorAuthenticationLoading)
        const validateTwoFactorAuthenticationLoading = computed(() => store.state.auth.validateTwoFactorAuthenticationLoading)
        const qrCodeTwoFactorAuthenticationLoading = computed(() => store.state.auth.qrCodeTwoFactorAuthenticationLoading)
        const qrCode = computed(() => store.state.auth.qrCodeTwoFactorAuthentication)
        const defaultProfileForm = () => {
            return {
                fields: {
                    name: null,
                    email: null
                },
                errors: {}
            }
        }
        const defaultPasswordForm = () => {
            return {
                fields: {
                    password: null,
                    password_confirmation: null
                },
                errors: {}
            }
        }
        const defaultTwoFactorAuthenticationForm = () => {
            return {
                fields: {
                    two_factor_code: null
                },
                errors: {}
            }
        }

        const profileForm = reactive(defaultProfileForm())
        const passwordForm = reactive(defaultPasswordForm())
        const twoFactorAuthenticationForm = reactive(defaultTwoFactorAuthenticationForm())

        watch(getProfile, (state, prevState) => {
            if (!qrCode.value && !state.two_factor_confirm && state.two_factor_secret) {
                authService.qrCodeTwoFactorAuthentication()
            }

            if (state) {
                profileForm.fields.name = state.name
                profileForm.fields.email = state.email
            }
        })

        const handleProfileSubmit = async () => {
            await userService.updateProfile(profileForm.fields).then(async () => {
                await toastMessageService.add({
                    detail: 'Профиль обновлен'
                })
                await authService.getAuthUser()
                await authService.storeAuthUserToStorage(store.state.auth.user)
            }).catch(exception => formService.fillErrors(exception, profileForm))
        }

        const handlePasswordSubmit = async () => {
            await userService.changePassword(passwordForm.fields).then(async () => {
                resetForm()
                await toastMessageService.add({
                    detail: 'Пароль обновлен'
                })
            }).catch(exception => formService.fillErrors(exception, passwordForm))
        }

        const resetForm = () => {
            Object.assign(profileForm, defaultProfileForm())
            Object.assign(passwordForm, defaultPasswordForm())
            Object.assign(twoFactorAuthenticationForm, defaultTwoFactorAuthenticationForm())
        }

        const enableTwoFactorAuthentication = async () => {
            await authService.enableTwoFactorAuthentication()
            await authService.qrCodeTwoFactorAuthentication()
            await userService.getProfile()
        }
        const disableTwoFactorAuthentication = () => {
            authService.disableTwoFactorAuthentication().then(() => {
                toastMessageService.add({
                    severity: 'info',
                    detail: 'Двухфакторная защита отключена'
                })
                userService.getProfile()
            })
        }
        const validateTwoFactorAuthentication = () => {
            authService.validateTwoFactorAuthentication(twoFactorAuthenticationForm.fields).then(() => {
                userService.getProfile()
                toastMessageService.add({
                    detail: 'Двухфакторная защита включена'
                })
            }).catch(exception => formService.fillErrors(exception, twoFactorAuthenticationForm))
        }

        return {
            getProfileLoading,
            updateProfileLoading,
            changePasswordLoading,
            activeIndex,
            profileForm,
            passwordForm,
            getProfile,
            resetForm,
            handleProfileSubmit,
            handlePasswordSubmit,
            enableTwoFactorAuthentication,
            disableTwoFactorAuthentication,
            validateTwoFactorAuthentication,
            enableTwoFactorAuthenticationLoading,
            disableTwoFactorAuthenticationLoading,
            validateTwoFactorAuthenticationLoading,
            qrCodeTwoFactorAuthenticationLoading,
            qrCode,
            twoFactorAuthenticationForm
        }
    }
}
</script>
